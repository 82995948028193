var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _c("div", { staticClass: "wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c("sale-area", {
                      ref: "saleArea",
                      on: { "set-sale-select-id": _vm.areaSelect },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "depart_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "选择部门" },
                        model: {
                          value: _vm.form.depart_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "depart_id", $$v)
                          },
                          expression: "form.depart_id",
                        },
                      },
                      _vm._l(_vm.groupSelect, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.text },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "user_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "选择业务员" },
                        model: {
                          value: _vm.form.user_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user_id", $$v)
                          },
                          expression: "form.user_id",
                        },
                      },
                      _vm._l(_vm.staffList, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "查询时段:", prop: "time" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "210px" },
                      attrs: {
                        type: "daterange",
                        "range-separator": "-",
                        "value-format": "yyyy-MM-dd",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.time,
                        callback: function ($$v) {
                          _vm.time = $$v
                        },
                        expression: "time",
                      },
                    }),
                  ],
                  1
                ),
                _c("div"),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c("sale-area", {
                      ref: "saleArea2",
                      attrs: { place: "选择对比区域" },
                      on: { "set-sale-select-id": _vm.areaSelect2 },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "eq_depart_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "选择对比部门" },
                        model: {
                          value: _vm.form.eq_depart_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "eq_depart_id", $$v)
                          },
                          expression: "form.eq_depart_id",
                        },
                      },
                      _vm._l(_vm.groupSelect, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.text },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "eq_user_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "选择对比业务员" },
                        model: {
                          value: _vm.form.eq_user_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "eq_user_id", $$v)
                          },
                          expression: "form.eq_user_id",
                        },
                      },
                      _vm._l(_vm.staffList, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "对比时段:", prop: "time2" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "210px" },
                      attrs: {
                        type: "daterange",
                        "range-separator": "-",
                        "value-format": "yyyy-MM-dd",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.time2,
                        callback: function ($$v) {
                          _vm.time2 = $$v
                        },
                        expression: "time2",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c("brand-select", {
                      ref: "brandSelect",
                      staticStyle: { width: "140px" },
                      attrs: { place: "全部品牌" },
                      on: { "brand-select-change": _vm.brandSelectId },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleQuery },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c("el-button", { on: { click: _vm.handleExport } }, [
                      _vm._v("导出"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c(
              "el-table-column",
              {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          item.label == "查询目标销售家数" ||
                          item.label == "对比目标销售家数"
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlermarker(row, item.label)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "border" }, [
                                    _vm._v(_vm._s(row[item.prop])),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                item.label == "查询目标终端总数" ||
                item.label == "查询目标销售家数" ||
                item.label == "查询目标铺市率"
                  ? _c("template", { slot: "header" }, [
                      _c("h3", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c("markerdiv", { ref: "markercom" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }